angular.module('app.controllers').controller('SpeakersCtrl', ['$scope', '$rootScope', 'APISpeakers', 'Table'].append(function(self, root, APISpeakers, Table) {
  var ref, tableName;
  tableName = 'c.speakers.' + (((ref = root.user) != null ? ref.shortcode : void 0) || 'guest');
  return self.speakers = Table({
    name: tableName,
    type: "full",
    resource: APISpeakers,
    params: {
      limit: 24,
      orderby: 'name'
    }
  });
}));

angular.module('app.controllers').controller('SpeakersEditCtrl', ['$scope', '$rootScope', 'APISpeakers'].append(function(self, root, APISpeakers) {
  self.id = parseInt(self.get('id')) || null;
  self.dataLoad = true;
  return APISpeakers.get({
    id: self.id,
    with_exhibitor: true,
    _entry: 'speaker'
  }, function(data) {
    var ref;
    self.data = data;
    self.dataLoad = false;
    self.data_d = null;
    if ((ref = self.data) != null ? ref.file_url : void 0) {
      return self.data_d = {
        id: self.data.id,
        exhibitor_id: null,
        entry_id: null,
        download_file: self.data.file_url,
        title: self.data.file_name
      };
    }
  });
}));

angular.module('app.controllers').controller('SpeakersModalCtrl', ['$scope', '$rootScope', '$timeout', 'APISpeakers', 'UI'].append(function(self, root, $timeout, APISpeakers, UI) {
  self.speakerModalEl = document.getElementById('speakerModal');
  if (self.speakerModalEl) {
    self.spModal = bootstrap.Modal.getOrCreateInstance(self.speakerModalEl);
    self.speakerModalEl.addEventListener('hidden.bs.modal', function(event) {
      self.$emit('hideSpeakerModal');
      return self.$applyAsync();
    });
    self.speakerModalEl.addEventListener('show.bs.modal', function(event) {
      return self.$applyAsync();
    });
  }
  self.dataLoad = false;
  self.backBtn = false;
  self.saveForm = function(form) {
    return self.spCForm = form;
  };
  self.resetData = function(data = null) {
    var ref;
    self.dataLoad = false;
    self.data = data;
    self.data_d = null;
    if ((ref = self.data) != null ? ref.file_url : void 0) {
      return self.data_d = {
        id: self.data.id,
        exhibitor_id: null,
        entry_id: null,
        download_file: self.data.file_url,
        title: self.data.file_name
      };
    }
  };
  self.view = true;
  self.toggleSpeakerView = function(view = null) {
    if (view == null) {
      if (!self.ha()) {
        return root.lockModal.show();
      }
      return self.view = !self.view;
    }
    return self.view = view;
  };
  self.$on('onToggleSpeakerView', function(event, view = true) {
    return self.toggleSpeakerView(view);
  });
  // show modal
  self.$on('showSpeakerModal', function(event, data = null, back = false) {
    if (data == null) {
      return;
    }
    self.spModal.show();
    self.backBtn = back;
    if (angular.isObject(data)) {
      self.resetData(data);
    } else {
      self.dataLoad = true;
      APISpeakers.get({
        id: data
      }, function(data) {
        return self.resetData(data);
      });
    }
    return self.$applyAsync();
  });
  // hide modal
  return self.$on('hideSpeakerModal', function(event) {
    self.toggleSpeakerView(true);
    self.backBtn = false;
    self.resetData();
    return self.$applyAsync();
  });
}));
