angular.module('app.controllers').controller 'SpeakersCtrl', [
  '$scope', '$rootScope', 'APISpeakers', 'Table'
].append (self, root, APISpeakers, Table) ->
  
  tableName = 'c.speakers.' + (root.user?.shortcode || 'guest')

  self.speakers = Table
    name: tableName
    type: "full"
    resource: APISpeakers
    params:       
      limit: 24
      orderby: 'name'

angular.module('app.controllers').controller 'SpeakersEditCtrl', [
  '$scope', '$rootScope', 'APISpeakers'
].append (self, root, APISpeakers) ->
  
  self.id = parseInt(self.get('id')) || null

  self.dataLoad = true
  APISpeakers.get {id: self.id, with_exhibitor: true, _entry: 'speaker'}, (data) ->
    self.data = data
    self.dataLoad = false

    self.data_d   = null

    if self.data?.file_url
      self.data_d = 
        id: self.data.id
        exhibitor_id: null
        entry_id: null
        download_file: self.data.file_url
        title: self.data.file_name
    

angular.module('app.controllers').controller 'SpeakersModalCtrl', [
  '$scope', '$rootScope', '$timeout', 'APISpeakers', 'UI'
].append (self, root, $timeout, APISpeakers, UI) ->
  
  self.speakerModalEl = document.getElementById('speakerModal')

  if self.speakerModalEl
    self.spModal = bootstrap.Modal.getOrCreateInstance(self.speakerModalEl)
  
    self.speakerModalEl.addEventListener('hidden.bs.modal', (event) ->
      self.$emit 'hideSpeakerModal'
      self.$applyAsync()
    )

    self.speakerModalEl.addEventListener('show.bs.modal', (event) ->
      self.$applyAsync()
    )

  self.dataLoad = false
  self.backBtn  = false

  self.saveForm = (form) ->
    self.spCForm = form
    
  self.resetData = (data = null) ->
    self.dataLoad = false  
    self.data     = data
    self.data_d   = null

    if self.data?.file_url
      self.data_d = 
        id: self.data.id
        exhibitor_id: null
        entry_id: null
        download_file: self.data.file_url
        title: self.data.file_name


  self.view = true

  self.toggleSpeakerView = (view = null) ->    
    unless view?
      return root.lockModal.show() unless self.ha()
      return self.view = !self.view 
    self.view = view

  self.$on 'onToggleSpeakerView', (event, view = true) ->
    self.toggleSpeakerView(view)

  # show modal
  self.$on 'showSpeakerModal', (event, data = null, back = false) ->
    return unless data?
    
    self.spModal.show()
    
    self.backBtn = back

    if angular.isObject(data)
      self.resetData(data)
    else
      self.dataLoad = true
      APISpeakers.get {id: data}, (data) ->
        self.resetData(data)

    self.$applyAsync()


  # hide modal
  self.$on 'hideSpeakerModal', (event) ->
    self.toggleSpeakerView(true)
    self.backBtn = false
    self.resetData()
    self.$applyAsync()
